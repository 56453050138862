import React from 'react'
import Layout from '../components/layout'
import SubmitRsvp from '../components/submitRsvp'
import Helmet from 'react-helmet'

const Generic = (props) => (
    <Layout>
        <Helmet>
            <title>RSVP</title>
            <meta name="description" content="Registry" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                <h2>RSVP</h2>
                    
                    <SubmitRsvp />
                    </div>
            </section>
        </div>

    </Layout>
)

export default Generic