import React from 'react'

import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Footer from './Footer'
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

class SubmitRsvp extends React.Component {
  constructor() {
    super();
    this.state = {
      attendees: "",
      comments: "",
      attending: true,
      friday: false,
      sunday: false,
      prompt: "Submit"
    };

        
    

  }

  componentDidMount() {
    var config = {
          apiKey: "AIzaSyB8SqfSwV8gY_k0QryQyWVAdpjzK8SySok",
          authDomain: "wedding-235700.firebaseapp.com",
          databaseURL: "https://wedding-235700.firebaseio.com",
          projectId: "wedding-235700",
          storageBucket: "wedding-235700.appspot.com",
          messagingSenderId: "1009818755390"
        }
        if (!firebase.apps || !firebase.apps.length) {
            firebase.initializeApp(config);
        }
  }

  addRsvp = state => {

    
      // Get a key for a new Post.
      var newPostKey = firebase.database().ref().child('rsvps').push().key;

      // Write the new post's data simultaneously in the posts list and the user's post list.
      var updates = {};
      updates['/rsvps/' + newPostKey] = state;

      return firebase.database().ref().update(updates);
  };

  onAttendingFilled = evt => {
    this.setState({ attendees: evt.target.value });
  };

  onAttending = evt => {
    this.setState({ attending: evt.target.checked });
  };

  onNotAttending = evt => {
    this.setState({ attending: !evt.target.checked });
  };

  onFriday = evt => {
    this.setState({ friday: evt.target.checked });
  };

  onSunday = evt => {
    this.setState({ sunday: evt.target.checked });
  };

  onComments = evt => {
    this.setState({ comments: evt.target.value });
  };

  handleSubmit = evt => {
    this.addRsvp(this.state)
        var newState = {
        attendees: "",
        comments: "",
        friday: false,
        sunday: false,
        attending: true,
        prompt: "Thanks!"
    };
    this.setState(newState);

  };

  render() {
    const { attendees, attending } = this.state;
    const isEnabled = attendees.length > 0;
    const isAttending = attending;
    return (
      <section>
                
        <div className="field">
            <label htmlFor="name">Who is or is not attending?</label>
            <input type="text" name="name" id="name" 
          value={this.state.attendees} onChange={this.onAttendingFilled}  />
        </div>
        <ul className="actions">
        </ul>
        <div className="field">
            <input type="radio" id="radio-overall-no" name="radio-overall" checked={this.state.attending}  onChange={this.onAttending} />
            <label htmlFor="radio-overall-no">Excited to attend</label>
        </div>

        <div className="field">
            <input type="radio" id="radio-overall-yes" name="radio-overall" checked={!this.state.attending}  onChange={this.onNotAttending} />
            <label htmlFor="radio-overall-yes">Sadly must decline</label>
        </div>
        <ul className="actions">
        </ul>
        <div className="field">
            <input type="checkbox" id="checkbox-friday" name="checkbox-friday"  onChange={this.onFriday}  disabled={!isAttending} />
            <label htmlFor="checkbox-friday">Friday night dinner</label>
        </div>
        <div className="field">
            <input type="checkbox" id="checkbox-sunday" name="checkbox-sunday"  onChange={this.onSunday}disabled={!isAttending} />
            <label htmlFor="checkbox-sunday">Sunday morning brunch</label>
        </div>
        <ul className="actions">
        </ul>
        <div className="field">
            <label htmlFor="message">Questions? Words of wisdom?</label>
            <textarea name="message" id="message" value={this.state.comments}  rows="6" placeholder="Leave your email and Jenny or Sam will get back to you posthaste."  onChange={this.onComments} ></textarea>
        </div>
        <ul className="actions">
        </ul>
        <ul className="actions">
            <li><input type="submit" value={this.state.prompt} className="special" onClick={this.handleSubmit} disabled={!isEnabled}/></li>
        </ul>
                
            </section>
    );
  }
}

export default SubmitRsvp
